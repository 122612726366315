exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-research-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/research/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-research-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-work-experience-js": () => import("./../../../src/pages/work-experience.js" /* webpackChunkName: "component---src-pages-work-experience-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

